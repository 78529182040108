@import "styles/core.scss";
.guideCardButtonContainer {
  @include removeDefaultButtonStyles;
  text-align: unset;
  cursor: pointer;
  border-radius: var(--radius-xl);
  overflow: hidden;
  border: var(--borders-weight-sm) solid var(--color-border-separator);
  width: 100%;
  display: block;

  @include focusOrHover {
    img {
      @include cardImageZoom;
      overflow: hidden;
    }
  }

  &.sm {
    border: none;
    border-radius: var(--radius-md);
    width: 145px;
    height: 171px;

    .guideCardBody {
      padding: var(--space-150);
      justify-content: flex-start;
      height: 90px;

      .guideCardTitle {
        @include heading-100;
        color: var(--color-text-secondary);
        height: auto;
      }
    }
  }
}

.guideCardImageContainer {
  overflow: hidden;
  width: 100%;
  aspect-ratio: 1.5;
}

.guideCardImage {
  object-fit: cover;
  aspect-ratio: 1.5;
  background-color: var(--color-neutral-200);
  width: 100%;
  height: 100%;
  border-radius: inherit;
  transition: transform 0.3s ease-in-out;
}

.guideCardBody {
  @include flex-column;
  justify-content: space-between;
  flex: auto;
  padding: var(--space-300);
  background-color: var(--color-background-primary);
  width: 100%;
  gap: var(--space-300);

  em {
    font-style: normal;
    color: var(--color-text-primary);
  }

  p {
    margin: 0;
  }

  .guideCardTitle {
    @include lineClamp(3);
    @include heading-300;
    color: var(--color-text-secondary);
    height: 90px;
  }
}

.guideCardTrailCount {
  align-self: 'flex-end';
  min-height: 19.5px;
}
