@import "styles/core.scss";
.guidesContent {
  width: 100%;
  max-width: 1790px;
  margin: 0 auto;
}

.header {
  @include flex-column;
  gap: var(--space-100);

  @include page-width-sm-up {
    margin-bottom: var(--space-450);
  }

  @include page-width-md-up {
    margin-bottom: var(--space-600);
  }

  @include page-width-xl {
    margin-bottom: var(--space-800);
  }

  @include page-width-xs {
    margin-bottom: var(--space-800);
  }

  @include page-width-sm-down {
    gap: var(--space-450);
  }
}

.plusBadge {
  @include page-width-sm-down {
    width: 20px;
    height: 20px;
  }
}

.title {
  @include flex-row;
  align-items: center;
  gap: var(--space-150);
  margin: 0;

  @include page-width-sm-down {
    gap: var(--space-100);
  }
}

.titleText {
  @include heading-400;
  margin: 0;

  @include page-width-lg-up {
    @include heading-600;
  }
}
